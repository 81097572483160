import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
import { authenticated, publicOnly } from './guards/auth'
import { feature as featureGuard } from './guards/featureGuard'
import { state as stateGuard } from './guards/state'
import { permission as permissionGuard } from './guards/permissionGuard'
import Health from './views/Health.vue'
import Dashboard from './views/Dashboard.vue'
import Verification from './views/Verification.vue'
import Login from './modules/auth/Login/index.vue'
import Logout from './modules/auth/Logout.vue'
import PasswordResetRequest from './modules/auth/PasswordResetRequest/index.vue'
import AuthCallback from './modules/auth/Callback.vue'
import CompanyCreating from './modules/auth/CompanyCreating.vue'
import featureNames from './lib/featureNames'
import { hasRequiredPaymentData } from './guards/paymentGuard'
import { leadsViewGuard } from '@/modules/leads/quards/leadsGuard'
const { isNavigationFailure, NavigationFailureType } = VueRouter

const LeadDetails = () => import(/*  webpackPrefetch: true, webpackChunkName: "leads" */ './views/LeadDetails.vue')
const Leads = () => import(/*  webpackPrefetch: true, webpackChunkName: "leads" */ './views/Leads.vue')
const Settings = () => import(/*  webpackPrefetch: true, webpackChunkName: "settings" */ './views/Settings.vue')
const Calendar = () => import(/*  webpackPrefetch: true, webpackChunkName: "calendar" */ './views/Calendar.vue')
const ContentCreator = () => import(/*  webpackPrefetch: true, webpackChunkName: "content-creator" */ './views/ContentCreator.vue')
const ProductPackages = () => import(/*  webpackPrefetch: true, webpackChunkName: "content-creator" */ './views/ProductPackages.vue')
const ProductFinder = () => import(/*  webpackPrefetch: true, webpackChunkName: "content-creator" */ './views/ProductFinder.vue')
const Performance = () => import(/*  webpackPrefetch: true, webpackChunkName: "performance" */ './views/Performance.vue')
const Valuation = () => import(/*  webpackPrefetch: true, webpackChunkName: "valuation" */ './views/Valuation.vue')
const Landingpage = () => import(/*  webpackPrefetch: true, webpackChunkName: "landingpage" */ './views/Landingpage.vue')
const Landingpages = () => import(/*  webpackPrefetch: true, webpackChunkName: "landingpages" */ './views/Landingpages.vue')
const Topics = () => import(/*  webpackPrefetch: true, webpackChunkName: "topics" */ './modules/topics')
const HelpCenter = () => import(/*  webpackPrefetch: true, webpackChunkName: "help-center" */ './views/HelpCenter.vue')
const Legal = () => import(/*  webpackPrefetch: true, webpackChunkName: "legal" */ './views/Legal.vue')
const StatisticDashboard = () => import(/*  webpackPrefetch: true, webpackChunkName: "legal" */ './views/StatisticDashboard.vue')

const Onboarding = () => import(/* webpackChunkName: "onboarding" */ './views/Onboarding.vue')
const OnboardingValidation = () => import(/* webpackChunkName: "onboarding" */ './views/OnboardingValidation.vue')
const Inactive = () => import(/* webpackChunkName: "inactive" */ './views/Inactive.vue')
const LicenseAgreement = () => import(/* webpackChunkName: "licenseAgreement" */ './views/LicenseAgreement.vue')
const Avv = () => import(/* webpackChunkName: "avv" */ './views/Avv.vue')

const Partner = () => import(/* webpackChunkName: "partner" */ './views/Partner.vue')
const Privacy = () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
const Registration = () => import(/* webpackChunkName: "registration" */ './views/Registration.vue')

const Signup = () => import(/* webpackChunkName: "signup" */ './modules/auth/Signup.vue')
const Register = () => import(/* webpackChunkName: "signup" */ './modules/auth/Register/Register.vue')
const Payment = () => import(/* webpackChunkName: "signup" */ './modules/payment/Payment.vue')

const TokenSignUp = () => import(/* webpackChunkName: "tokenSignUp" */ './modules/auth/TokenSignUp.vue')

const OnofficeActivationSync = () => import(/* webpackChunkName: "onofficeActivation" */ './modules/crm/onoffice/OnofficeActivationSync.vue')
const OnofficeActivationApp = () => import(/* webpackChunkName: "onofficeActivation" */ './modules/crm/onoffice/OnofficeActivationApp.vue')
const OnofficeConfirmation = () => import(/* webpackChunkName: "onofficeConfirmation" */ './modules/crm/onoffice/OnofficeConfirmation.vue')
const OnofficeServiceApp = () => import(/* webpackChunkName: "onofficeConfirmation" */ './modules/crm/onoffice/OnofficeServiceApp.vue')

const Products = () => import(/* webpackChunkName: "products" */ './views/Products.vue')

Vue.use(VueRouter)

const routes = ({ feature, state, permission }) => [
  {
    path: '/_health',
    name: 'health',
    component: Health
  },
  {
    path: '/onoffice/activate',
    name: 'onoffice-activate',
    component: OnofficeActivationSync
  },
  {
    path: '/onoffice/activate/app',
    name: 'onoffice-activate-app',
    component: OnofficeActivationApp
  },
  {
    path: '/onoffice/confirmation/:token',
    name: 'onoffice-confirmation',
    component: OnofficeConfirmation,
    beforeEnter: multiguard([authenticated, feature(featureNames.CRM), state(['ACTIVE'])])
  },
  {
    path: '/onoffice/service/app',
    name: 'onoffice-service-app',
    component: OnofficeServiceApp
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/flowfact/:token?',
    name: 'flowfact',
    props: { partner: 'FLOWFACT' },
    component: TokenSignUp,
    beforeEnter: publicOnly
  },
  {
    path: '/propstack/:token?',
    name: 'propstack',
    props: { partner: 'PROPSTACK' },
    component: TokenSignUp,
    beforeEnter: publicOnly
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    beforeEnter: publicOnly
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/auth/signup/:token?',
    name: 'signup',
    component: Signup,
    beforeEnter: multiguard([publicOnly, feature(featureNames.SIGNUP)])
  },
  {
    path: '/auth/register',
    name: 'register',
    component: Register,
    beforeEnter: multiguard([publicOnly, feature(featureNames.SIGNUP)])
  },
  {
    path: '/payment/:step?',
    name: 'payment',
    component: Payment,
    beforeEnter: multiguard([authenticated, hasRequiredPaymentData, state(['REGISTRATION.BILLING'])])
  },
  {
    path: '/change-package-payment/:step?',
    name: 'change-package-payment',
    component: Payment,
    beforeEnter: multiguard([authenticated, hasRequiredPaymentData])
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    beforeEnter: multiguard([authenticated, feature(featureNames.SIGNUP), state(['SELF_ONBOARDING'])])
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
    component: AuthCallback
  },
  {
    path: '/company-creation',
    name: 'company-creation',
    component: CompanyCreating
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/signup-markero/:step',
    name: 'signup-markero',
    beforeEnter: multiguard([authenticated, state(['REGISTRATION.ANAMNESIS'])]),
    component: ProductFinder
  },
  {
    path: '/product-packages',
    name: 'product-packages',
    component: ProductPackages
  },
  {
    path: '/auth/password-reset',
    name: 'password-reset-request',
    component: PasswordResetRequest,
    beforeEnter: publicOnly
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: {
      path: '/onboarding/company'
    }
  },
  {
    path: '/onboarding/:step',
    name: 'onboardingStep',
    component: Onboarding,
    beforeEnter: multiguard([authenticated, state(['ONBOARDING.USER'])]),
    meta: {
      tracking: {
        useActualPath: true
      }
    }
  },
  {
    path: '/onboarding-validation',
    name: 'onboardingValidation',
    component: OnboardingValidation,
    beforeEnter: multiguard([authenticated, state(['ONBOARDING.VALIDATION'])])
  },
  {
    path: '/inactive',
    name: 'inactive',
    component: Inactive,
    beforeEnter: multiguard([authenticated, state(['INACTIVE', 'CREATED'])])
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])])
  },
  {
    path: '/leads',
    name: 'leads',
    query: { filter: '' },
    component: Leads,
    beforeEnter: multiguard([authenticated, feature(featureNames.LEAD), permission('lead:read'), state(['ACTIVE']), leadsViewGuard]),
    meta: {
      tracking: {
        ignorePageView: true
      }
    }
  },
  {
    path: '/leads/:view(list|pipeline)',
    name: 'leads-view',
    query: { filter: '' },
    component: Leads,
    beforeEnter: multiguard([authenticated, feature(featureNames.LEAD), permission('lead:read'), state(['ACTIVE'])])
  },
  {
    path: '/leads/:id',
    name: 'lead-details',
    component: LeadDetails,
    beforeEnter: multiguard([authenticated, feature(featureNames.LEAD), permission('lead:read'), state(['ACTIVE'])])
  },
  {
    path: '/profile',
    name: 'profile',
    redirect: {
      path: '/settings/profile'
    }
  },
  {
    path: '/landingpages',
    name: 'landingpages',
    component: Landingpages,
    beforeEnter: multiguard([authenticated, feature(featureNames.LANDINGPAGE), permission('material:read'), state(['ACTIVE'])])
  },
  {
    path: '/landingpages/:slug',
    name: 'landingpage-details',
    component: Landingpage,
    beforeEnter: multiguard([authenticated, feature(featureNames.LANDINGPAGE), permission('material:read'), state(['ACTIVE'])]),
    meta: {
      tracking: {
        useActualPath: true
      }
    }
  },
  {
    path: '/downloads',
    name: 'downloads',
    redirect: {
      name: 'landingpages'
    }
  },
  {
    path: '/topics',
    name: 'topics',
    component: Topics,
    beforeEnter: multiguard([
      authenticated,

      state(['ACTIVE'])
    ])
  },
  {
    path: '/valuation',
    name: 'valuation',
    component: Valuation,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.VALUATION, (config) => config.isInAppValuationEnabled),
      permission('valuation:read'),
      state(['ACTIVE'])
    ])
  },
  {
    path: '/content-creator',
    name: 'content-creator',
    component: ContentCreator,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.CONTENT_CREATOR),
      permission('ai:read'),
      state(['ACTIVE'])
    ])
  },
  {
    path: '/performance',
    name: 'performance',
    component: Performance,
    beforeEnter: multiguard([authenticated, feature(featureNames.PERFORMANCE_MARKETING), permission('performance:read'), state(['ACTIVE'])])
  },
  {
    path: '/statistic-dashboard',
    name: 'statistic-dashboard',
    component: StatisticDashboard,
    beforeEnter: multiguard([authenticated, feature(featureNames.STATISTIC_DASHBOARD), permission('lead:read'), state(['ACTIVE'])])
  },
  {
    path: '/support',
    name: 'support',
    component: HelpCenter,
    beforeEnter: multiguard([authenticated, permission('support:read'), state(['ACTIVE'])])
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    beforeEnter: multiguard([authenticated, feature(featureNames.PARTNER), permission('partner:read'), state(['ACTIVE'])])
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: {
      path: '/settings/company'
    }
  },
  {
    path: '/settings/:section',
    name: 'Settings',
    component: Settings,
    beforeEnter: multiguard([authenticated, permission('settings:read'), state(['ACTIVE'])]),
    meta: {
      tracking: {
        useActualPath: true
      }
    }
  },
  {
    path: '/verify/:id',
    name: 'verify',
    component: Verification,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])])
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    beforeEnter: multiguard([authenticated, feature(featureNames.EVENT_CALENDAR), permission('calendar:read')])
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    beforeEnter: multiguard([authenticated])
  },
  {
    path: '/license-agreement',
    name: 'licenseAgreement',
    component: LicenseAgreement,
    beforeEnter: multiguard([authenticated, feature(featureNames.LICENSE_AGREEMENT), permission('legal:read')])
  },
  {
    path: '/avv',
    name: 'avv',
    component: Avv,
    beforeEnter: multiguard([authenticated, permission('legal:read')])
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.APP_BRANDING, (config) => config.showPrivacyArea),
      permission('legal:read')
    ])
  },
  {
    path: '*',
    name: '404',
    redirect: {
      name: 'home'
    }
  }
]

const createRouter = apollo => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes({
      feature: (feature, activeCheckFn) => featureGuard(apollo, feature, activeCheckFn),
      state: (states) => stateGuard(apollo, states),
      permission: (permission) => permissionGuard(apollo, permission)
    })
  })

  /**
   * Do not throw an exception if push is rejected by redirection from navigation guard
   */
  const originalPush = router.push
  router.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
      if (isNavigationFailure(err, NavigationFailureType.redirected)) {
        // const reg = new RegExp('^Redirected when going from "[a-z_.\\/]+" to "[a-z_.\\/]+" via a navigation guard.$')
        // if (reg.test(err.message)) {
        return Promise.resolve(false)
      }
      return Promise.reject(err)
    })
  }

  /**
   * Handle page view tracking
   */
  router.beforeEach((to, from, next) => {
    if (!to.meta.tracking?.ignorePageView) {
      router.app.$tracking.pageView(to)
    }

    return next()
  })

  return router
}

export default createRouter
